import WishlistController from '@/utils/Controllers/WishlistController';
import EVENTS from '@/utils/Constants/Events';

export const checkWishlistIcon = () => {
  WishlistController.updateWishlistIcon({
    selector: '.js-wishlist-icon',
    activeClass: 'icon-wishlist-active',
    inactiveClass: 'icon-wishlist',
    textSelector: '.js-wishlist-text',
  });
};

export default () => {
  DLG.EVE.off(EVENTS.WISHLIST.SAVED.LIST, checkWishlistIcon);
  DLG.EVE.on(EVENTS.WISHLIST.SAVED.LIST, checkWishlistIcon);
  checkWishlistIcon();
};
