import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Utils
import { isTrue } from '@/utils/Common/Booleans';
import _isEmpty from 'lodash/isEmpty';

const OmnibusPricing = ({
  omnibusScenario,
  mainPriceValue,
  mainPriceFormattedValue,
  oldPriceValue,
  oldPriceFormattedValue,
  lastLowerPriceValue,
  lastLowerPriceFormattedValue,
}) => {
  const { t } = useTranslation();

  let struckPrice = '';
  let struckPriceLabel = '';

  const setStruckPrice = (priceValue, priceFormattedValue, label) => {
    if ((priceValue || 0) > mainPriceValue) {
      struckPrice = priceFormattedValue;
      struckPriceLabel = label;
    }
  };

  if (
    isTrue(DLG.config.oldPriceEnabled) &&
    (oldPriceValue || 0) > (mainPriceValue || 0)
  ) {
    setStruckPrice(oldPriceValue, oldPriceFormattedValue, '');

    if (omnibusScenario !== 0) {
      struckPriceLabel = t('common:omnibus.recommended.price.label');

      if (omnibusScenario === 3) {
        setStruckPrice(
          lastLowerPriceValue,
          lastLowerPriceFormattedValue,
          t('common:omnibus.last.lower.price.label'),
        );
      }
    }
  }

  return (
    <ul className='dlg-omnibus-price'>
      <li className='dlg-omnibus-price__main-price'>
        {mainPriceFormattedValue}
      </li>
      {!_isEmpty(struckPrice) && (
        <li className='dlg-omnibus-price__old-price'>
          <span className='dlg-omnibus-price__strike-price'>{struckPrice}</span>
          {!_isEmpty(struckPriceLabel) && (
            <span className='dlg-omnibus-price__label'>{struckPriceLabel}</span>
          )}
        </li>
      )}
    </ul>
  );
};

OmnibusPricing.propTypes = {
  omnibusScenario: PropTypes.number,
  mainPriceValue: PropTypes.number.isRequired,
  mainPriceFormattedValue: PropTypes.string.isRequired,
  oldPriceValue: PropTypes.number.isRequired,
  oldPriceFormattedValue: PropTypes.string.isRequired,
  lastLowerPriceValue: PropTypes.number.isRequired,
  lastLowerPriceFormattedValue: PropTypes.string.isRequired,
};

OmnibusPricing.defaultProps = {
  omnibusScenario: 0,
};

export default OmnibusPricing;
