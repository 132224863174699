import Logger from '@/utils/Logger';

let swiperNineLib;

export const LazySwiperNine = async () => {
  try {
    if (!swiperNineLib) {
      const data = await import(
        /* webpackChunkName: "swiper-9.4.1" */ 'swiper/swiper-bundle'
      );
      swiperNineLib = data.default;
    }
  } catch (e) {
    Logger.error(e);
    Logger.error('LazyLoad::Something went wrong loading Swiper library');
  }
  return swiperNineLib;
};

export const LazySwiperNineWhenVisible = (target, callback) => {
  const observerOptions = {
    rootMargin: '0px 0px 60% 0px',
  };
  const observerCallback = async (entries, observer) => {
    entries.forEach(async entry => {
      try {
        if (!entry.isIntersecting) return;
        observer.unobserve(entry.target);
        if (!swiperNineLib) {
          swiperNineLib = await LazySwiperNine();
        }
        callback(entry.target, swiperNineLib);
      } catch (e) {
        Logger.error(e);
        Logger.error(
          'LazyLoad::Something went wrong loading Swiper library, processing the following target:',
          entry.target,
        );
      }
    });
  };
  const observer = new IntersectionObserver(observerCallback, observerOptions);
  Array.from(target).forEach(el => {
    observer.observe(el);
  });
};
