import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { productImageByFormat } from '@/utils/Common/ProductImage';

// import components
import PriceBox from '@/components/organisms/OrderProductBox/PriceBox';

// Constants
import CONST from '@/utils/Constants/General';

const ItemBox = ({ cartItem, showPrice = true, isBundle = false }) => {
  const { t } = useTranslation();

  const productConfigurable =
    cartItem?.configurableProductDataConfigurationId &&
    cartItem?.configurableProductDataSnapshot;
  const thumbnailBASE64 = cartItem?.configurableProductDataSnapshot;

  let productImageUrl = '';
  let productImageAltText = '';
  if (isBundle) {
    const hasCartIcon = cartItem.rootGroup?.rootBundleTemplate?.images.find(
      image => image.format === CONST.PRODUCT.IMAGE.FORMAT.CART_ICON,
    );

    const iconFormat = hasCartIcon
      ? CONST.PRODUCT.IMAGE.FORMAT.CART_ICON
      : CONST.PRODUCT.IMAGE.FORMAT.THUMBNAIL;

    const productImageData = productImageByFormat(
      cartItem.rootGroup?.rootBundleTemplate?.images,
      iconFormat,
    );

    productImageUrl = productImageData?.url;
    productImageAltText = productImageData?.altText;
  } else {
    productImageUrl = productConfigurable
      ? thumbnailBASE64
      : cartItem.productImage?.xs?.url;

    productImageAltText = productConfigurable
      ? thumbnailBASE64
      : cartItem.productImage?.altText;
  }

  const productName = isBundle
    ? cartItem.rootGroup.rootBundleTemplate.rootBundleTemplateName
    : cartItem.productName;

  return (
    <div className='dln-orderSumm__contentRow'>
      <div className='dln-orderSumm__item'>
        <div className='dln-image dln-orderSumm__itemPicWrap'>
          <img
            className='dln-image__image'
            src={productImageUrl}
            alt={productImageAltText}
          />
        </div>
        <div className='dln-orderSumm__itemDetails'>
          <h5>{productName}</h5>
          {showPrice && (
            <PriceBox
              actualPriceValue={cartItem.actualPriceValue}
              actualPrice={cartItem.actualPrice}
              oldPriceValue={cartItem.oldPriceValue}
              oldPrice={cartItem.oldPrice}
            />
          )}
          <p className='dln-qty'>
            {t('checkout:checkout.summary.qty')}{' '}
            {isBundle ? '1' : cartItem.quantity}
          </p>
        </div>
      </div>
    </div>
  );
};

ItemBox.propTypes = {
  cartItem: PropTypes.shape().isRequired,
  showPrice: PropTypes.bool,
  isBundle: PropTypes.bool,
};

ItemBox.defaultProps = {
  showPrice: true,
  isBundle: false,
};

export default ItemBox;
