import { isNutribullet, isUSSite } from '@/utils/Common/Booleans';
import CONST from '@/utils/Constants/General';

export const replace = (a, b, c) => a.replace(b, c);

export const split = (a, b) => {
  if (a.includes(b)) {
    return a.split(b);
  }
  return '';
};

export const hasSameCountryNextUrl = (nextUrl, responseUrl) =>
  nextUrl === responseUrl ||
  split(nextUrl, '/p/')[1] === split(responseUrl, '/p/')[1] ||
  split(nextUrl, '/c/')[1] === split(responseUrl, '/c/')[1];

export const bytesToMb = bytes => bytes / 1024 / 1024;

export const validFileSizeMB = (file, limitSize, allowEmptyFile = true) => {
  if (allowEmptyFile && !file) return true;
  return file.size < limitSize;
};

export const hasImageSizes = image =>
  image && image.l != null && image.m != null && image.s != null;

export const getBoundingParentRect = (entry, parent = entry.parentElement) => {
  const out = {
    left: entry.offsetLeft,
    right: parent.offsetWidth - entry.offsetLeft - entry.offsetWidth,
    top: entry.offsetTop,
    bottom: parent.offsetHeight - entry.offsetTop - entry.offsetHeight,
    w: entry.offsetWidth,
    h: entry.offsetHeight,
  };
  return out;
};

export const classListRemove = (el, classes) => {
  classes.forEach(classToRemove => el.classList.remove(classToRemove));
};

export const classListAdd = (el, classes) => {
  classes.forEach(classToAdd => el.classList.remove(classToAdd));
};

export const chunk = (array, size) =>
  array.reduce(
    (chunks, el, i) =>
      (i % size ? chunks[chunks.length - 1].push(el) : chunks.push([el])) &&
      chunks,
    [],
  );

export const parseJSON = data => {
  let output = {};
  try {
    output = JSON.parse(data);
  } catch (e) {
    console.error('parseJSON::', e.message);
  }
  return output;
};

export const getActualBrand = () =>
  isNutribullet ? CONST.BRAND.DELONGHI : DLG.config.brand;

export const getFormattedDistance = formattedDistance =>
  isUSSite && formattedDistance.match(/km/gi)
    ? `${Math.round(
        (parseFloat(formattedDistance.replace(/,/g, '.')) * 0.621371 +
          Number.EPSILON) *
          10,
      ) / 10} Mi`.replace(/\./g, ',')
    : formattedDistance;

/**
 * toggle class/es (one or more) of the provided element/s (one or more)
 * The 'condition' param is optional.
 */
export const toggleClasses = (els, classes, condition) => {
  const toggleCb = el => {
    if (!Array.isArray(classes)) el.classList.toggle(classes, condition);
    else classes.map(cls => el.classList.toggle(cls, condition));
  };

  if (els.length) {
    Array.from(els).map(el => toggleCb(el));
  } else toggleCb(els);
};

export const isBreakpoint = breakpoint => window.innerWidth < breakpoint;

export const isBreakpointMin = breakpoint => window.innerWidth > breakpoint;

/**
 * Transforms HTML entities in a string into their corresponding characters.
 * @param text
 * @returns {string}
 */
export const decodeText = text => {
  const txt = document.createElement('textarea');
  txt.innerHTML = text;
  return txt.value;
};

export const isTouchEnabled = () =>
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;

export const scrollIntoViewWithOffset = (selector, offset = 0) => {
  const el = document.querySelector(selector);
  if (el) {
    const y =
      el.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      offset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};
