export const parse = cart => {
  const newCartEntries = [];

  const existingRootEntryGroups = new Set();

  if (cart && cart.entries) {
    cart.entries.forEach(entry => {
      if (entry.rootEntryGroup) {
        if (!existingRootEntryGroups.has(entry.rootEntryGroup)) {
          const childrens = cart.entries.filter(
            childEntry => childEntry.rootEntryGroup === entry.rootEntryGroup,
          );
          if (childrens.length > 0) {
            entry.bundleEntries = childrens;
          }
          if (cart.rootGroups && cart.rootGroups.length > 0) {
            entry.rootGroup = cart.rootGroups.find(
              e => e.groupNumber === entry.rootEntryGroup,
            );
          }
          newCartEntries.push(entry);
          existingRootEntryGroups.add(entry.rootEntryGroup);
        }
      } else {
        newCartEntries.push(entry);
      }
    });
  }

  return newCartEntries;
};

export default {
  parse,
};
