/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { productImageByFormat } from '@/utils/Common/ProductImage';
import CONST from '@/utils/Constants/General';
import { useTranslation } from 'react-i18next';

// import components
import PriceBox from '@/components/organisms/OrderProductBox/PriceBox';

import { parse } from '@/utils/Common/CartUtils';

const MiniCartNotEmpty = ({ cart }) => {
  const { t } = useTranslation();
  const [cartEntriesUpdated, setCartEntriesUpdated] = useState([]);

  useEffect(() => {
    if (cart.entries != null && cart.entries.length > 0) {
      setCartEntriesUpdated(parse(cart));
    }
  }, [cart.entries]);

  const MiniCartBundleItems = cartEntriesUpdated.map(entry => {
    const isBundle = entry?.bundleEntries?.length;

    const productConfigurable =
      entry?.configurableProductDataConfigurationId &&
      entry?.configurableProductDataSnapshot;
    const thumbnailBASE64 = entry?.configurableProductDataSnapshot;

    let cartIconUrl = '';
    let cartIconAltText = '';

    if (isBundle) {
      const hasCartIcon =
        entry.rootGroup?.rootBundleTemplate?.images &&
        entry.rootGroup?.rootBundleTemplate?.images.find(
          image => image.format === CONST.PRODUCT.IMAGE.FORMAT.CART_ICON,
        );

      const iconFormat = hasCartIcon
        ? CONST.PRODUCT.IMAGE.FORMAT.CART_ICON
        : CONST.PRODUCT.IMAGE.FORMAT.THUMBNAIL;

      const cartIcon = productImageByFormat(
        entry.rootGroup?.rootBundleTemplate?.images,
        iconFormat,
      );

      cartIconUrl = productConfigurable ? thumbnailBASE64 : cartIcon?.url;
      cartIconAltText = cartIcon?.altText;
    } else {
      const cartIcon = productImageByFormat(
        entry.bundleEntries
          ? entry.rootGroup?.rootBundleTemplate?.images
          : entry.productImages,
        CONST.PRODUCT.IMAGE.FORMAT.CART_ICON,
      );

      cartIconUrl = productConfigurable ? thumbnailBASE64 : cartIcon?.s?.url;
      cartIconAltText = cartIcon?.altText;
    }

    return (
      <div
        key={`${entry.entryNumber}-${entry.productCode}`}
        className='dln-addItem dln-addItem--basic'
      >
        <div className='dln-addItem__picture'>
          <img
            className='dln-addItem__img'
            src={cartIconUrl}
            alt={cartIconAltText}
          />
        </div>
        <div className='dln-addItem__content'>
          {!entry.bundleEntries && (
            <>
              <span className='dln-addItem__title'>{entry.productName}</span>
              <div className='dln-addItem__price'>
                <PriceBox
                  omnibusScenario={entry.omnibusScenario}
                  actualPrice={entry.actualPrice}
                  actualPriceValue={entry.actualPriceValue}
                  oldPrice={entry.oldPrice}
                  oldPriceValue={entry.oldPriceValue}
                  lastLowerPrice={entry.lastLowerPrice?.formattedValue}
                  lastLowerPriceValue={entry.lastLowerPrice?.value}
                />
              </div>
              <div className='dln-addItem__quantity'>
                <span>
                  {t('cart:cart.miniCart.quantity')} <b>{entry.quantity}</b>
                </span>
              </div>
            </>
          )}
          {entry.bundleEntries && (
            <>
              <span className='dln-addItem__title'>
                {entry.rootGroup?.rootBundleTemplate?.name}
              </span>
              <div className='dln-addItem__price'>
                <PriceBox
                  actualPrice={entry.actualPrice}
                  oldPrice={entry.oldPrice}
                />
              </div>
              <p
                className='dln-addItem__bundleDescription'
                dangerouslySetInnerHTML={{
                  __html:
                    entry.rootGroup?.rootBundleTemplate?.htmlDescription || '',
                }}
              />
              <div className='dln-addItem__quantity'>
                <span>
                  {t('cart:cart.miniCart.quantity')} <b>{entry.quantity}</b>
                </span>
              </div>
              <p
                className='dln-addItem__bundleDescription'
                dangerouslySetInnerHTML={{
                  __html:
                    entry.rootGroup?.rootBundleTemplate?.description || '',
                }}
              />
            </>
          )}
        </div>
      </div>
    );
  });

  return <div className='dln-miniCart__content'>{MiniCartBundleItems}</div>;
};

MiniCartNotEmpty.propTypes = {
  cart: PropTypes.shape(),
};

MiniCartNotEmpty.defaultProps = {
  cart: null,
};

export default MiniCartNotEmpty;
