/* eslint-disable no-new */
import { checkWishlistIcon } from '@/delonghi/Controllers/DELWishlistController';
import { LazySwiperNine } from '@/utils/Controllers/LazySwiperNineController';
import initPdpAccessoriesController from '@/utils/Controllers/PdpAccessoriesController';
import { emitGtmListingImpression } from '@/utils/Controllers/GTM/Operations/ListingImpression';

const initAccessoriesCarousel = async () => {
  const slider = document.querySelector('.js-del-pdp__accessories');
  if (!slider) return;

  const nextEl = slider.querySelector('.swiper-button-next');
  const prevEl = slider.querySelector('.swiper-button-prev');
  const pagination = slider.querySelector('.swiper-pagination');

  const LazySwiperNineLib = await LazySwiperNine();
  new LazySwiperNineLib(slider, {
    watchOverflow: true,
    slidesPerView: 1,
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
    },
    pagination: {
      clickable: true,
      el: pagination,
      type: 'bullets',
    },
    navigation: {
      nextEl,
      prevEl,
    },
    on: {
      init() {
        slider.style.opacity = 1;
      },
    },
  });
};

export const jsEventsCallback = () => {
  initAccessoriesCarousel();
  emitGtmListingImpression();
  checkWishlistIcon();
};

export default () => {
  initPdpAccessoriesController(jsEventsCallback);
};
